.chatbubble {
	position: fixed;
	bottom: 0;
	right: 32px;
	transform: translateY(300px);
	transition: transform 0.3s ease-in-out;
	z-index: 100;
}

.title {
	color: #fff;
	text-align: center;
	text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
}

.chatbubble.opened {
	transform: translateY(0);
}

.chatbubble .unexpanded {
	display: block;
	background-color: #f79563;
	padding: 10px 16px 10px;
	position: relative;
	cursor: pointer;
	width: 350px;
	border-radius: 10px 10px 0 0;
}

.chatbubble .expanded {
	height: 300px;
	width: 350px;
	background-color: #fff;
	text-align: left;
	padding: 10px;
	color: #333;
	text-shadow: none;
	font-size: 14px;
}

.chatbubble .expanded .login-screen.active {
	height: 242px;
	width: 340px;
}

.chatbubble .expanded .login-screen.active form {
	height: 242px;
	width: 340px;
}

.chatbubble .expanded .login-screen.active form input,
.chatbubble .expanded .login-screen.active form button {
	width: 98% !important;
}

.chatbubble .chat-window {
	overflow: auto;
}

.chatbubble .loader-wrapper {
	margin-top: 50px;
	text-align: center;
}

.chatbubble .messages {
	display: none;
	list-style: none;
	margin: 0 0 50px;
	padding: 0;
}

.chatbubble .messages li {
	width: 85%;
	float: left;
	padding: 10px;
	border-radius: 5px 5px 5px 0;
	font-size: 14px;
	background: #c9f1e6;
	margin-bottom: 10px;
}

.chatbubble .messages li .sender {
	font-weight: 600;
}

.chatbubble .messages li.support {
	float: right;
	text-align: right;
	color: #fff;
	background-color: #e33d3d;
	border-radius: 5px 5px 0 5px;
}

.chatbubble .chats .input {
	position: absolute;
	bottom: 0;
	padding: 10px;
	left: 0;
	width: 100%;
	background: #f0f0f0;
	display: none;
}

.chatbubble .chats .input .form-group {
	width: 80%;
}

.chatbubble .chats .input input {
	width: 100%;
}

.chatbubble .chats .input button {
	width: 20%;
}

.chatbubble .chats {
	display: none;
}

.chatbubble .login-screen {
	margin-top: 20px;
	display: none;
}

.chatbubble .chats.active,
.chatbubble .login-screen.active {
	display: block;
}

/* Loader Credit: https://codepen.io/ashmind/pen/zqaqpB */
.chatbubble .loader {
	color: #e23e3e;
	font-family: Consolas, Menlo, Monaco, monospace;
	font-weight: bold;
	font-size: 10vh;
	opacity: 0.8;
}

.chatbubble .loader span {
	display: inline-block;
	-webkit-animation: pulse 0.4s alternate infinite ease-in-out;
	animation: pulse 0.4s alternate infinite ease-in-out;
}

.chatbubble .loader span:nth-child(odd) {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}

@-webkit-keyframes pulse {
	to {
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		opacity: 0.5;
	}
}

@keyframes pulse {
	to {
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		opacity: 0.5;
	}
}
